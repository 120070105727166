@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./animations.css";

@layer components {
  .input-primary {
    @apply block w-full px-5 py-5 text-gray-900 border border-gray-300 rounded-md cursor-pointer bg-primary-50 hover:border-primary-800 sm:text-sm dark:bg-primary-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
  }

  .input-primary-with-icon {
    @apply block w-full py-5 pl-4 text-gray-900 border border-gray-300 rounded-md cursor-pointer pr-9 bg-primary-50 hover:border-primary-800 sm:text-sm dark:bg-primary-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
  }

  .input-thin {
    @apply block w-full p-2.5 text-gray-900 border border-gray-300 rounded-md cursor-pointer bg-primary-750 hover:border-primary-800 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
  }

  .sidebar-button {
    @apply py-4 pl-2 pr-2 rounded-lg hover:bg-gray-600 hover:text-white;
  }

  .button-success {
    @apply px-5 py-3 text-sm font-medium text-white bg-green-700 rounded-md focus:outline-none focus:ring-4 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800;
  }

  .button-info {
    @apply inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-md bg-primary-750 hover:bg-gray-700 focus:ring-4 focus:outline-none dark:bg-primary-750 dark:hover:bg-gray-700;
  }

  .button-danger {
    @apply inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-md hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700;
  }
}
.ql-editor {
  white-space: pre-wrap; /* Omogućava da se novi redovi pravilno prikazuju */
}

.icon-download::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"%3E%3Cpath stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" /%3E%3C/svg%3E');
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
}

.dark .icon-download::before {
  filter: brightness(0) invert(1);
}
.icon-comment::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"%3E%3Cpath stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" /%3E%3C/svg%3E');
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
}
.dark .icon-comment::before {
  filter: brightness(0) invert(1);
}
.icon-detail::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"%3E%3Cpath stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Zm3.75 11.625a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" /%3E%3C/svg%3E');
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
}
.dark .icon-detail::before {
  filter: brightness(0) invert(1);
}
.icon-document::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6"%3E%3Cpath stroke-linecap="round" stroke-linejoin="round" d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 0 0-1.883 2.542l.857 6a2.25 2.25 0 0 0 2.227 1.932H19.05a2.25 2.25 0 0 0 2.227-1.932l.857-6a2.25 2.25 0 0 0-1.883-2.542m-16.5 0V6A2.25 2.25 0 0 1 6 3.75h3.879a1.5 1.5 0 0 1 1.06.44l2.122 2.12a1.5 1.5 0 0 0 1.06.44H18A2.25 2.25 0 0 1 20.25 9v.776" /%3E%3C/svg%3E');
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
}

.dark .icon-document::before {
  filter: brightness(0) invert(1);
}
.icon-show-more::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"%3E%3Cpath stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" /%3E%3C/svg%3E');
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
}
.dark .icon-show-more::before {
  filter: brightness(0) invert(1);
}

.icon-trash::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6"%3E%3Cpath stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" /%3E%3C/svg%3E');
  display: inline-block;
  width: 1rem;
  height: 1rem;
}

.dark .icon-trash::before {
  filter: brightness(0) invert(1);
}

.icon-sendMessage::before {
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6"%3E%3Cpath stroke-linecap="round" stroke-linejoin="round" d="M21.75 9v.906a2.25 2.25 0 0 1-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 0 0 1.183 1.981l6.478 3.488m8.839 2.51-4.66-2.51m0 0-1.023-.55a2.25 2.25 0 0 0-2.134 0l-1.022.55m0 0-4.661 2.51m16.5 1.615a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V8.844a2.25 2.25 0 0 1 1.183-1.981l7.5-4.039a2.25 2.25 0 0 1 2.134 0l7.5 4.039a2.25 2.25 0 0 1 1.183 1.98V19.5Z" /%3E%3C/svg%3E');
  display: inline-block;
  width: 1rem;
  height: 1rem;
}

.dark .icon-sendMessage::before {
  filter: brightness(0) invert(1);
}

/* Keep footer on the bottom of the page, by applying this style to #root element, and by applying (directly) top auto margin to footer element */
@layer base {
  #root {
    @apply flex flex-col min-h-screen;
  }
}

/* Adding full width to datepicker field */
.react-datepicker-wrapper {
  width: 100%;
}

/* SCROLLBAR */
/* Hide the scrollbar track, but still allow it to be scrolled */
::-webkit-scrollbar {
  width: 0.3rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4a5568; /* Set your desired color here */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2d3748; /* Darker color on hover */
}

/* Track */
::-webkit-scrollbar-track {
  background: #cbd5e0; /* Set your desired background color here */
}

.vertical {
  border-left: 2px solid blue;
  height: 1rem;
}

/* Arrow up */
.arrow-up {
  width: 0;
  height: 0;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #111827;
  position: absolute;
  top: -12px;
}

.recharts-wrapper svg {
  overflow: inherit !important;
}
/* hide loader on file - dropzone*/
.dropzone .dz-preview .dz-progress {
  display: none !important;
}
